.carteirinha-container {
    height: 397px;
    display: flex;
    justify-content: center;
    scale: 0.8;
}
@media print {
    body {
        visibility: hidden;
    }
    header{
        display: none !important;
    }

    @page {
        margin: 0;
    }
    .carteirinha-container {
        position: fixed;
        height: 100%;
        top: -20%;
        scale: 0.51;
        width: 100%;
        visibility: visible !important;
        left: -27%;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        margin: 5%;
    }

}